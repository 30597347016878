import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyFacturacionReembolso: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Facturar Reembolsos
            </h2>
            <p>
                Las devoluciones parciales pueden emitirse como notas de crédito, si existió una factura antes de la devolución o reembolso. <br />
                Cuando se realiza el reembolso antes de la factura, la factura emitida se ajsuta con el reembolso y no es necesaria la nota de crédito.
            </p>
            <ImageFooted explanation={`Las notas de crédito (reembolsos) se emiten igual y en la misma sección que las facturas sencillas manuales, cuando se detecta un reembolso no facturado, se muestra la interfaz de arriba, con su característico color naranja.`}>
                <IMG_Shopify file={'billRefund'} />
            </ImageFooted>
            <ImageFooted explanation={`Puedes emitir mas de una nota de crédito (reembolsos), las cuales se mostrarán en la sección de detalle del pedido en FiscalPOP arriba de la factura original.`}>
                <IMG_Shopify file={'billRefundComplete'} />
            </ImageFooted>
            <p>
            En la imagen se aprecian dos reembolsos: <br/> El primero con $0.00 tiene el mismo UUID que la factura, indicando que se detecto un reembolso AL MOMENTO de emitir la factura original. <br /> El segundo que tiene cantidad y un UUID diferente, es una nota de crédito posterior a la factura original.
            </p>
            <SectionNavigate previous={`/ecommerce/shopify/facturacion-global`} next={`/ecommerce/shopify/configurar-impuestos`}/>

        </div>
    </Layout>
)

export default ShopifyFacturacionReembolso;